const styledTheme = {
  device: {
    xs: '419px',
    sm: '767px',
    md: '1023px',
    lg: '1441px',
  },
};

export default styledTheme;
